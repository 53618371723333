<script setup>
import { ref, computed } from "vue";
import { solutionsArray } from "@/data/solutions.js";
import {
    ArrowDownIcon
} from "@/components/icons";

// Lottie Animation
import { LottieAnimation } from "lottie-web-vue";
import solutionsAnimation from "/public/images/lottie/solutions.json";
import mobileAnimation from "/public/images/lottie/phone.json";

// Swiper
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Particle JS
import { loadSlim } from "tsparticles-slim";

const particlesInit = async engine => {
    await loadSlim(engine);
};

const options = computed(() => {
    return [
    {
        fpsLimit: 120,
        fullScreen: {
            enable: false
        },
        particles: {
            color: {
                value: '#ffffff'
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 100
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 5 },
            }
        },
        detectRetina: true
    }]
});

const solutions = solutionsArray();

const selectedSection = ref([]);

const isExpanded = (sectionId) => {
    return selectedSection.value.includes(sectionId);
}

const expandItem = (sectionId) => {
    selectedSection.value.push(sectionId);
}

const compressItem = (sectionId) => {
    const index = selectedSection.value.indexOf(sectionId);
    selectedSection.value.splice(index, 1);
}
</script>

<template>
    <div class="flex flex-col">
        <!-- Home Banner -->
        <swiper
            :slides-per-view="1"
            :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
            }"
            :loop="true"
            :pagination="true"
            class="w-full xl:mt-[7rem]"
        >
            <swiper-slide v-for="banner in solutions.banners" :key="banner.id">
                <div 
                    :style="`background: url('images/homepage/${banner.file}') no-repeat; background-size: cover; background-position: center;`" 
                    :class="[banner.position, `relative flex items-center gap-6 w-full 2xl:h-[900px] lg:h-[750px] h-[600px]`]"
                >
                    <div class="flex flex-col justify-center md:text-center md:gap-10 gap-8 2xl:max-w-[60rem] max-w-[55rem] max-sm:p-6">
                        <div :class="[banner.titleStyle, `leading-tight font-extrabold 2xl:text-[50px] lg:text-[40px] text-[35px]`]">
                            {{ banner.title }}
                        </div>
                        <div :class="[banner.descriptionStyle, `font-bold 2xl:text-[35px] lg:text-[29px] text-[22px]`]">
                            {{ banner.description }}
                        </div>
                        <h1 
                            v-if="banner.actionText"
                            :class="[banner.actionTextStyle, `2xl:text-[26px] text-[20px]`]"
                        >
                            {{ banner.actionText }}
                        </h1>
                        <img 
                            v-if="banner.actionImage"
                            :src="`images/homepage/${banner.actionImage}`"
                        >
                        <div 
                            v-if="banner.buttonImage"
                            class="flex justify-center"
                        >
                            <a href="https://app.smicos.com/register" target="_blank">
                                <img
                                    :src="`images/homepage/${banner.buttonImage}`"
                                    alt="banner button"
                                >
                            </a>
                        </div>
                        <!-- <a 
                            v-if="banner.id === 1"
                            href="https://app.smicos.com/register" 
                            class="flex justify-center" 
                            :class="`relative z-[${bannerZIndex}]`" target="_blank"
                        >
                            <span
                                class="bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-8 py-4 text-[18px] text-white font-semibold cursor-pointer"
                                :class="`relative z-[${bannerZIndex}]`"
                            >
                                Get Smicos for <span class="font-bold">FREE</span>
                            </span>
                        </a>  -->
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <!-- Feature Section -->
        <div class="relative flex md:flex-row flex-col items-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] p-8 z-20">
            <div class="absolute top-0 right-0 w-[50rem] h-full">
                <vue-particles
                    id="tsparticles"
                    :particlesInit="particlesInit"
                    :options="options"
                    style="height: 100%;"
                />
            </div>
            <div class="md:hidden block flex-1">
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    style="height: 40rem;"
                />
            </div>
            <div class="flex-1 flex flex-col gap-10 2xl:pl-20">
                <h2 class="text-white text-center font-bold lg:text-[55px] md:text-[45px] text-[35px]">
                    We Understand<br> Your Business <span class="bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent font-extrabold">Needs</span>
                </h2>
                <LottieAnimation
                    :animation-data="solutionsAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="0.5"  
                    style="font-size: 40px;"
                />
                <!-- <div class="flex flex-wrap gap-14 items-center">
                    <div
                        v-for="solution in solutions"
                        class="flex items-center gap-1 md:w-[40%] w-full"
                    >
                        <component :is="solution.icon" class="w-7"/>
                        <div class="flex items-center">
                            <div class="w-5 h-5 bg-[#E37C0D] rounded-full solutions-bullet"></div>
                            <span class="relative -left-2 text-white font-bold">
                                {{ solution.title }}
                            </span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="md:block hidden flex-1 p-10">
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    class="lg:h-[40rem]"
                />
            </div>
        </div>

        <!-- Transform -->
        <div class="flex flex-col gap-10 items-center">
            <div class="flex justify-center text-center w-full pt-6">
                <div class="flex flex-col w-full max-w-[80%]">
                    <h1 class="font-extrabold lg:text-[45px] md:text-[40px] text-[30px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent">
                        Smicos Game Changer for AI-driven Wins
                    </h1>
                    <div class="flex 2xl:flex-row flex-col justify-between 2xl:gap-0 gap-4 w-full 2xl:px-20 2xl:py-10 py-4">
                        <div class="flex-1 flex flex-col">
                            <span class="2xl:text-[48px] text-[40px] font-extrabold">
                                =
                            </span>
                            <span class="font-semibold">
                                your conversion machine
                            </span>
                        </div>
                        <div class="flex-1 flex flex-col">
                            <span class="2xl:text-[48px] text-[40px] font-bold">
                                24/7
                            </span>
                            <span class="font-semibold">
                                common questions answered in real-time
                            </span>
                        </div>
                        <div class="flex-1 flex flex-col">
                            <span class="2xl:text-[48px] text-[40px] font-bold">
                                100%
                            </span>
                            <span class="font-semibold">
                                smart analytics for better decision making
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="text-[28px] font-bold text-center">
                Transform your business operations with Smicos chatbot solutions.
            </h2>
            <!-- Smicos Dashboard -->
            <div class="flex 2xl:flex-row flex-col justify-between 2xl:gap-0 gap-10 w-full max-w-[80%]">
                <div class="2xl:w-[55%] w-full">
                    <img
                        :src="`/images/solutions/sections/dashboard.png`"
                        class="w-full shadow-lg border rounded-lg"
                        alt="smicos dashboard"
                    >
                </div>
                <div class="2xl:w-[40%] w-full">
                    <div class="flex flex-col gap-2">
                        <span class="text-[18px] font-semibold pb-4">
                            Unlock valuable insights into how your audience interacts with your support system.
                        </span>
                        <template v-for="section in solutions.sections" :key="section.id">
                            <div v-if="section.section == `dashboard`">
                                <div
                                    v-if="isExpanded(section.id)"
                                    @click="compressItem(section.id)"
                                    class="flex flex-col gap-4 bg-[#D5EAFE] rounded-md p-4 cursor-pointer"
                                >
                                    <div class="flex items-center justify-between">
                                        <span class="font-semibold">
                                            {{ section.name }}
                                        </span>
                                        <ArrowDownIcon class="w-6"/>
                                    </div>
                                    <span class="text-justify">
                                        {{ section.description }}
                                    </span>
                                </div>
                                <div
                                    v-else
                                    @click="expandItem(section.id)"
                                    class="flex items-center justify-between bg-[#F4F2F2] rounded-md p-4 cursor-pointer"
                                >
                                    <span class="font-semibold">
                                        {{ section.name }}
                                    </span>
                                    <ArrowDownIcon class="w-6"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- Smicos Story Maker -->
            <div class="flex justify-center w-full py-10 bg-gradient-to-r from-[#E37C0D] to-[#F2CD59]">
                <div class="flex 2xl:flex-row flex-col justify-between 2xl:gap-0 gap-10 w-full max-w-[80%]">
                    <div class="2xl:w-[40%] w-full">
                        <div class="flex flex-col gap-2">
                            <span class="text-[18px] text-white font-semibold pb-4">
                                Keep customers hooked with engaging bot responses. Your automation tool for the most critical point of customer journey.
                            </span>
                            <template v-for="section in solutions.sections" :key="section.id">
                                <div v-if="section.section == `story-maker`">
                                    <div
                                        v-if="isExpanded(section.id)"
                                        @click="compressItem(section.id)"
                                        class="flex flex-col gap-4 bg-[#D5EAFE] rounded-md p-4 cursor-pointer"
                                    >
                                        <div class="flex items-center justify-between">
                                            <span class="font-semibold">
                                                {{ section.name }}
                                            </span>
                                            <ArrowDownIcon class="w-6"/>
                                        </div>
                                        <span class="text-justify">
                                            {{ section.description }}
                                        </span>
                                    </div>
                                    <div
                                        v-else
                                        @click="expandItem(section.id)"
                                        class="flex items-center justify-between bg-[#F4F2F2] rounded-md p-4 cursor-pointer"
                                    >
                                        <span class="font-semibold">
                                            {{ section.name }}
                                        </span>
                                        <ArrowDownIcon class="w-6"/>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="2xl:w-[55%] w-full">
                        <video
                            autoplay loop muted
                            class="w-full shadow-lg border rounded-lg"
                        >
                            <source :src="`/images/solutions/sections/story-maker.webm`" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
            <!-- Smicos Customer Database -->
            <div class="flex 2xl:flex-row flex-col justify-between 2xl:gap-0 gap-10 w-full max-w-[80%]">
                <div class="2xl:w-[55%] w-full">
                    <img
                        :src="`/images/solutions/sections/customer-database.png`"
                        class="w-full shadow-lg border rounded-lg"
                        alt="smicos story maker"
                    >
                </div>
                <div class="2xl:w-[40%] w-full">
                    <div class="flex flex-col gap-2">
                        <span class="text-[18px] font-semibold pb-4">
                            Accelerate issue resolution and elevate customer satisfaction with a smart ticketing system.
                        </span>
                        <template v-for="section in solutions.sections" :key="section.id">
                            <div v-if="section.section == `customer-database`">
                                <div
                                    v-if="isExpanded(section.id)"
                                    @click="compressItem(section.id)"
                                    class="flex flex-col gap-4 bg-[#D5EAFE] rounded-md p-4 cursor-pointer"
                                >
                                    <div class="flex items-center justify-between">
                                        <span class="font-semibold">
                                            {{ section.name }}
                                        </span>
                                        <ArrowDownIcon class="w-6"/>
                                    </div>
                                    <span class="text-justify">
                                        {{ section.description }}
                                    </span>
                                </div>
                                <div
                                    v-else
                                    @click="expandItem(section.id)"
                                    class="flex items-center justify-between bg-[#F4F2F2] rounded-md p-4 cursor-pointer"
                                >
                                    <span class="font-semibold">
                                        {{ section.name }}
                                    </span>
                                    <ArrowDownIcon class="w-6"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- Smicos Ticketing Tool -->
            <div class="flex justify-center w-full py-10 bg-gradient-to-r from-[#31549D] to-[#0A9EDF]">
                <div class="flex 2xl:flex-row flex-col justify-between 2xl:gap-0 gap-10 w-full max-w-[80%]">
                    <div class="2xl:w-[40%] w-full">
                        <div class="flex flex-col gap-2">
                            <span class="text-[18px] text-white font-semibold pb-4">
                                Effortlessly manage, assign, prioritize, and respond to customer requests, all in one place.
                            </span>
                            <template v-for="section in solutions.sections" :key="section.id">
                                <div v-if="section.section == `ticketing`">
                                    <div
                                        v-if="isExpanded(section.id)"
                                        @click="compressItem(section.id)"
                                        class="flex flex-col gap-4 bg-[#D5EAFE] rounded-md p-4 cursor-pointer"
                                    >
                                        <div class="flex items-center justify-between">
                                            <span class="font-semibold">
                                                {{ section.name }}
                                            </span>
                                            <ArrowDownIcon class="w-6"/>
                                        </div>
                                        <span class="text-justify">
                                            {{ section.description }}
                                        </span>
                                    </div>
                                    <div
                                        v-else
                                        @click="expandItem(section.id)"
                                        class="flex items-center justify-between bg-[#F4F2F2] rounded-md p-4 cursor-pointer"
                                    >
                                        <span class="font-semibold">
                                            {{ section.name }}
                                        </span>
                                        <ArrowDownIcon class="w-6"/>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="2xl:w-[55%] w-full">
                        <img
                            :src="`/images/solutions/sections/ticketing.png`"
                            class="w-full shadow-lg border rounded-lg"
                            alt="smicos story maker"
                        >
                    </div>
                </div>
            </div>
            <!-- Smicos Live Agent -->
            <div class="flex 2xl:flex-row flex-col justify-between 2xl:gap-0 gap-10 w-full max-w-[80%]">
                <div class="2xl:w-[55%] w-full">
                    <img
                        :src="`/images/solutions/sections/customer-database.png`"
                        class="w-full shadow-lg border rounded-lg"
                        alt="smicos story maker"
                    >
                </div>
                <div class="2xl:w-[40%] w-full">
                    <div class="flex flex-col gap-2">
                        <span class="text-[18px] font-semibold pb-4">
                            Accelerate issue resolution and elevate customer satisfaction with a smart ticketing system.
                        </span>
                        <template v-for="section in solutions.sections" :key="section.id">
                            <div v-if="section.section == `live-agent`">
                                <div
                                    v-if="isExpanded(section.id)"
                                    @click="compressItem(section.id)"
                                    class="flex flex-col gap-4 bg-[#D5EAFE] rounded-md p-4 cursor-pointer"
                                >
                                    <div class="flex items-center justify-between">
                                        <span class="font-semibold">
                                            {{ section.name }}
                                        </span>
                                        <ArrowDownIcon class="w-6"/>
                                    </div>
                                    <span class="text-justify">
                                        {{ section.description }}
                                    </span>
                                </div>
                                <div
                                    v-else
                                    @click="expandItem(section.id)"
                                    class="flex items-center justify-between bg-[#F4F2F2] rounded-md p-4 cursor-pointer"
                                >
                                    <span class="font-semibold">
                                        {{ section.name }}
                                    </span>
                                    <ArrowDownIcon class="w-6"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bg-orange-bullets{
    box-shadow: 0 0 90px 100px rgb(227, 124, 13, 1);
}

.bg-blue-bullets{
    box-shadow: 0 0 90px 100px rgba(0, 174, 238, 1);
}

.section1-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #F2CD59, #E37C0D);
}

.section2-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #E37C0D, #E1251B);
}

.section3-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #0A9EDF, #384590);
}

.section4-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #E1251B, #E37C0D);
}
</style>
