<script setup>
import { ref, computed } from "vue";
import {
    ArrowUpRightIcon
} from "../icons";

// Lottie Animation
import { LottieAnimation } from "lottie-web-vue";
import mobileAnimation from "/public/images/lottie/pricing.json";

// Particle JS
import { loadSlim } from "tsparticles-slim";

const particlesInit = async engine => {
    await loadSlim(engine);
};

const listDescription = ref({});
const listPackage = ref("");

const showTooltip = (list, item) => {
    listDescription.value = list;
    listPackage.value = item;
};

const hideTooltip = () => {
    listDescription.value = "";
};

const free = [
    {id: 1, name: "2 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "1,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "500 MB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 10, name: "Website and Facebook Integration", description: "Smicos Easy Integration to Website and Facebook Messenger"},
    {id: 11, name: "2 Roles (Admin and Sales)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin and Sales)"},
    {id: 12, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 13, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 14, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const starter = [
    {id: 1, name: "3 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "5,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "1 GB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 11, name: "Website, Facebook, and Instagram Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, and Instagram"},
    {id: 12, name: "Starter-Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data"},
    {id: 13, name: "3 Roles (Admin, Sales, and Live Agent)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin, Sales, and Live Agent)"},
    {id: 14, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 15, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 16, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const pro = [
    {id: 1, name: "5 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "10,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "3 GB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 11, name: "Website, Facebook, and Instagram Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, and Instagram"},
    {id: 12, name: "Pro-Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data"},
    {id: 13, name: "5 Roles (Admin, Sales, Marketing, and Live Agent)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin, Sales, Marketing and 2 Live Agent)"},
    {id: 14, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 15, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 16, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const advanced = [
    {id: 1, name: "10 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "100,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "5 GB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Training Feature", description: "Feature that allows you to enhance your Smicos bot’s understanding, responses, and overall performance. (Includes: Threads and Train Story)"},
    {id: 11, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 12, name: "Website, Facebook, and Instagram Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, and Instagram"},
    {id: 13, name: "Advance-Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data (Includes: All Reports)"},
    {id: 14, name: "7 Roles (Admin, Sales, Marketing, and Live Agents)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin, Sales, Marketing and 4 Live Agents)"},
    {id: 15, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 16, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 17, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const enterprise = [
    {id: 1, name: "Unlimited Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "Unlimited Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "Unlimited Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Training Feature", description: "Feature that allows you to enhance your Smicos bot’s understanding, responses, and overall performance. (Includes: Threads and Train Story)"},
    {id: 11, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 12, name: "Multiple Platform Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, Instagram, WhatsApp, and Viber"},
    {id: 13, name: "Full Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data (Includes: All Reports)"},
    {id: 14, name: "Unlimited Roles (Admin, Sales, Marketing, and Live Agent)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot"},
    {id: 15, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 16, name: "Dedicated Account Manager", description: "A dedicated account manager assigned to serve as a direct point of contact between you and the Smicos Team"},
    {id: 17, name: "Dedicated Technical Support", description: "A dedicated technical support assigned to resolve technical issues, provide guidance, and ensure your Smicos bot operates seamlessly"},
    {id: 18, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 19, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const inclusions = [
    { name: "Active Chatbots", free: "2", starter: "3", pro: "5", advanced: "10" },
    { name: "Valid Chats", free: "1,000", starter: "5,000", pro: "10,000", advanced: "100,000" },
    { name: "Integrations", free: "Website and Facebook", starter: "Website, Facebook and Instagram", pro: "Website, Facebook and Instagram", advanced: "Website, Facebook and Instagram" }
]

const options = computed(() => {
    return [
    {
        fpsLimit: 120,
        fullScreen: {
            enable: false
        },
        particles: {
            color: {
                value: '#00AEEE'
            },
            links: {
                color: '#00AEEE',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 100
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 5 },
            }
        },
        detectRetina: true
    }]
});
</script>

<template>
    <div class="flex flex-col">
        <!-- Home Banner -->
        <div class="relative flex items-center justify-center gap-6 w-full 2xl:h-[900px] h-[600px]">
            <div class="flex flex-col items-center justify-center md:text-center md:max-w-[45rem] max-w-full max-sm:p-8 z-20">
                <h1 class="text-[#1E1E1E] font-extrabold md:text-[50px] text-[35px] leading-tight 2xl:pb-[5rem] pb-[3rem]">
                    Choose <span class="bg-gradient-to-r from-[#E37C0D] to-[#E1251B] bg-clip-text text-transparent">chatbot plans</span> that suits your business needs.
                </h1>
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    class="w-[80rem] h-[10rem]"
                />
            </div>
            <div class="absolute top-0 right-0 w-full h-full z-10">
                <vue-particles
                    id="tsparticles"
                    :particlesInit="particlesInit"
                    :particlesLoaded="particlesLoaded"
                    :options="options"
                    style="height: 100%;"
                />
            </div>
        </div>

        <!-- Subscription Section -->
        <div class="flex flex-col items-center justify-center gap-5 h-[15rem] w-full bg-gradient-to-r from-[#F2CD59] to-[#E37C0D]">
            <span class="text-white md:text-[50px] text-[35px] font-extrabold text-center leading-tight">
                Smicos Subscriptions
            </span>
            <h2 class="text-black text-[22px] font-bold text-center">
                Take your business to the next level with Smicos Chatbot Subscriptions.
            </h2>
        </div>

        <!-- Pricing Section -->
        <div class="flex flex-col items-center justify-center md:gap-2 gap-4 p-8">
            <span class="md:text-[50px] text-[32px] font-extrabold text-transparent bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-center leading-tight">
                Compare Chatbot Features
            </span>
            <span class="font-bold text-[#1E1E1E] md:text-[25px] text-[22px] text-center">
                Choose the offer that suit your business needs.
            </span>
            <!-- Price list -->
            <div class="flex flex-wrap items-start justify-center gap-10 w-full md:mt-10">
                <!-- Packages -->
                <div class="flex flex-col gap-6">
                    <div class="flex 2xl:flex-nowrap flex-wrap gap-10">
                        <!-- Trial -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Free
                            </span>
                            <div
                                class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] md:h-[60rem]"
                            >
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            0
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in free"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `free`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59] max-sm:w-full">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `free`"
                                                class="absolute bottom-[100%] md:left-[100%] left-[40%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] md:max-w-[20rem] max-w-[10rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a 
                                href="https://app.smicos.com/register" 
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 lg:w-full w-[20rem]" 
                                target="_blank"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </a>
                        </div>
                        <!-- Starter -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Starter
                            </span>
                            <div
                                class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] md:h-[60rem]"
                            >
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            19
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                    Small Companies
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in starter"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `starter`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59] max-sm:w-full">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `starter`"
                                                class="absolute bottom-[100%] md:left-[100%] left-[50%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] md:max-w-[20rem] max-w-[10rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a 
                                href="https://app.smicos.com/register" 
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 lg:w-full w-[20rem]" 
                                target="_blank"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </a>
                        </div>
                        <!-- Pro -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Pro
                            </span>
                            <div class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] md:h-[60rem]">
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            79
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                    Small to Medium Companies
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in pro"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `pro`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59] max-sm:w-full">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `pro`"
                                                class="absolute bottom-[100%] md:left-[100%] left-[50%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] md:max-w-[20rem] max-w-[10rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a 
                                href="https://app.smicos.com/register" 
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 lg:w-full w-[20rem]" 
                                target="_blank"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </a>
                        </div>
                        <!-- Advanced -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Advanced
                            </span>
                            <div class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] md:h-[60rem]">
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            299
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                    Medium to Large Companies
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in advanced"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `advanced`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59] max-sm:w-full">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `advanced`"
                                                class="absolute bottom-[100%] md:left-[100%] left-[50%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] md:max-w-[20rem] max-w-[10rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a 
                                href="https://app.smicos.com/register" 
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 lg:w-full w-[20rem]" 
                                target="_blank"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </a>
                        </div>
                    </div>
                    <!-- Trial button -->
                    <div class="2xl:block hidden w-full px-6">
                        <a
                            href="https://app.smicos.com/register"
                            class="flex items-center justify-center gap-2 bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-6 py-2"
                            target="_blank"
                        >
                            <div class="text-[16px] text-white text-center font-semibold">
                                Subscribe Now
                            </div>
                            <ArrowUpRightIcon class="w-5 text-white"/>
                        </a>
                    </div>
                </div>
                <!-- Enterprise -->
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col items-center gap-4">
                        <span class="text-[#E1251B] text-[40px] font-extrabold">
                            Enterprise
                        </span>
                        <div
                            class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] md:h-[60rem]"
                        >
                            <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                Medium to Large Companies
                            </div>
                            <div class="flex flex-col gap-2">
                                <div
                                    v-for="list in enterprise"
                                    class="flex gap-2 cursor-default"
                                    @mouseenter="showTooltip(list, `enterprise`)"
                                    @mouseleave="hideTooltip"
                                >
                                    <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div class="relative hover:text-[#F2CD59] max-sm:w-full">
                                        <span>
                                            {{ list.name }}
                                        </span>
                                        <div
                                            v-if="listDescription.id && listPackage === `enterprise`"
                                            class="absolute bottom-[100%] md:right-[100%] left-[50%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] md:max-w-[20rem] max-w-[10rem] text-left"
                                            :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                        >
                                            {{ list.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a 
                            href="https://smicos.com/contact-us" 
                            class="2xl:hidden flex items-center justify-center gap-2 bg-[#00AEEE] hover:bg-[#00AEEE]/70 rounded-full px-6 py-2 lg:w-full w-[20rem]" 
                            target="_blank"
                        >
                            <div class="text-[16px] text-white text-center font-semibold">
                                Contact Sales
                            </div>
                        </a>
                    </div>
                    <div class="2xl:block hidden w-full px-6">
                        <a 
                            href="https://smicos.com/contact-us" 
                            class="flex items-center justify-center gap-2 bg-[#00AEEE] hover:bg-[#00AEEE]/70 rounded-full px-6 py-2" 
                            target="_blank"
                        >
                            <div class="text-[16px] text-white text-center font-semibold">
                                Contact Sales
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Features Section -->
        <div class="flex flex-col items-center justify-center gap-10 bg-gradient-to-r from-[#384590] to-[#01ADED] 2xl:p-20 p-8">
            <div class="flex flex-col gap-4 mb-5">
                <span class="font-extrabold md:text-[50px] text-[40px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent text-center">
                    Compare Smicos Features
                </span>
                <h2 class="font-bold text-white text-[26px] text-center">
                    Choose and compare our chatbot features that suits your business needs.
                </h2>
            </div>
            <div class="flex justify-center rounded-lg bg-[#FFF] border-[1px] border-[#F2CD59] md:max-w-[65%] w-full overflow-hidden">
                <div class="max-sm:max-w-[45rem] w-full max-sm:overflow-x-scroll md:p-10 p-6">
                    <div class="flex flex-col gap-4 max-sm:w-[65rem]">
                        <!-- headers -->
                        <div class="flex items-center justify-between gap-10 mb-4">
                            <div class="flex justify-center">
                                <div class="w-[10rem] md:text-[30px] text-[24px] text-[#00AEEE] font-bold">
                                    Features
                                </div>
                            </div>
                            <div class="flex justify-center">
                                <span class="border-2 border-[#00AEEE] text-[#00AEEE] font-bold md:text-[25px] text-[20px] text-center rounded-full py-2 w-[10rem]">
                                    Free
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="border-2 border-[#00AEEE] text-[#00AEEE] font-bold md:text-[25px] text-[20px] text-center rounded-full py-2 w-[10rem]">
                                    Starter
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="border-2 border-[#00AEEE] text-[#00AEEE] font-bold md:text-[25px] text-[20px] text-center rounded-full py-2 w-[10rem]">
                                    Pro
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="border-2 border-[#00AEEE] text-[#00AEEE] font-bold md:text-[25px] text-[20px] text-center rounded-full py-2 w-[10rem]">
                                    Advanced
                                </span>
                            </div>
                        </div>
                        <!-- body -->
                        <div 
                            v-for="inclusion in inclusions"
                            class="flex items-center justify-between gap-10 border-b-[2px] border-smicos-primary max-sm:w-full pb-2"
                        >
                            <div class="flex justify-center">
                                <span class="w-[10rem] md:text-[20px] text-[18px] font-bold">
                                    {{ inclusion.name }}
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="text-[#E1251B] font-bold text-center rounded-full py-2 w-[10rem] md:text-[20px] text-[18px]">
                                    {{ inclusion.free }}
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="text-[#E1251B] font-bold text-center rounded-full py-2 w-[10rem] md:text-[20px] text-[18px]">
                                    {{ inclusion.starter }}
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="text-[#E1251B] font-bold text-center rounded-full py-2 w-[10rem] md:text-[20px] text-[18px]">
                                    {{ inclusion.pro }}
                                </span>
                            </div>
                            <div class="flex justify-center">
                                <span class="text-[#E1251B] font-bold text-center rounded-full py-2 w-[10rem] md:text-[20px] text-[18px]">
                                    {{ inclusion.advanced }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="https://app.smicos.com/register" class="flex justify-center" target="_blank">
                <span class="bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-8 py-4 text-[20px] text-white font-semibold cursor-pointer z-[10] w-full text-center">
                    Get Smicos for <span class="font-bold">FREE</span>
                </span>
            </a>
            <!-- <div class="flex max-sm:flex-col items-center justify-center max-sm:gap-4">
                <div class="relative md:w-[50%] w-full">
                    <span class="absolute top-0 left-0 md:!p-8 p-4 md:text-[30px] text-white font-bold">
                        Features
                    </span>
                    <img :src="`/images/pricing/features.png`" class="w-full" alt="`features logo`">
                    <div class="absolute bottom-0 right-0 flex items-end md:block hidden w-[65%]">
                        <a href="https://app.smicos.com/register" class="flex justify-center" target="_blank">
                            <span class="bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-6 py-2 text-[16px] text-white font-semibold cursor-pointer z-[101] w-full text-center">
                                Get Smicos for <span class="font-bold">FREE</span>
                            </span>
                        </a>
                    </div>
                </div>
                <a href="https://app.smicos.com/register" class="md:hidden flex justify-center" target="_blank">
                    <span class="bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-6 py-2 text-[14px] text-white font-semibold cursor-pointer z-[101] w-full text-center">
                        Get Smicos for <span class="font-bold">FREE</span>
                    </span>
                </a>
            </div> -->
        </div>
    </div>
</template>
